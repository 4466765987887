<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
    >
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} evaluator-host history<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'researcher.administration.evaluator-history.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />
            <div
              v-if="loading"
              id="loading-bg"
            >
              <div
                class="loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
            <template v-else>
              <div
                class="card"
              >
                <div
                  v-b-toggle="`collapseContent`"
                  class="card-header"
                >
                  <h3 class="cart-title">
                    <a data-action="collapse">{{ item.id ? 'Edit' : 'New' }} evaluator history</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <b-collapse
                    :id="`collapseContent`"
                    visible
                  >
                    <!-- sólo se muestra "show" si hay texto en el textarea -->
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Host Institution 1</label>
                            <v-select
                              v-model="item.hostInstitution1"
                              label="name"
                              :clearable="false"
                              :options="hosts"
                              :get-option-key="option => option.id"
                              @search="onSearch({ name: $event }, 'institutions/filter')"
                            />
                          </div>
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Department / Group / Unit</label>
                            <v-select
                              v-model="item.department1"
                              label="name"
                              :disabled="!item.hostInstitution1"
                              :options="item.hostInstitution1 ? item.hostInstitution1.departments : []"
                              :get-option-key="option => option.id"
                              :placeholder="!item.hostInstitution1 ? 'Choose a institution' : 'Select a department'"
                              @input="departmentPrioritized"
                            />
                          </div>
                          <div
                            v-if="!item.department1 && item.department1_table"
                            class="alert alert-danger mt-1 p-1"
                          >
                            Current department name: <strong>{{ item.department1_table }}</strong>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Host Institution 2</label>
                            <v-select
                              v-model="item.hostInstitution2"
                              label="name"
                              :options="hosts"
                              :get-option-key="option => option.id"
                              @search="onSearch({ name: $event }, 'institutions/filter')"
                            />
                          </div>
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Department / Group / Unit</label>
                            <v-select
                              v-model="item.department2"
                              label="name"
                              :disabled="!item.hostInstitution2"
                              :options="item.hostInstitution2 ? item.hostInstitution2.departments : []"
                              :get-option-key="option => option.id"
                              :placeholder="!item.hostInstitution2 ? 'Choose a institution' : 'Select a department'"
                            />
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-sm-6">
                          <h4>Evaluator</h4>
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Current evaluator</label>
                            <v-select
                              v-model="item.evaluator"
                              label="name"
                              :clearable="false"
                              :options="evaluatorsTotal"
                              :get-option-key="option => option.id"
                              @search="onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2 d-flex">
                          <div class=" mt-auto mb-1">
                            <a
                              class="btn btn-dark w-100"
                              :href="$router.resolve({name: 'admin.users.create', query: { preselectedRoles: 'evaluator' }}).href"
                              target="_blank"
                            ><i data-feather="external-link" /> Add evaluator</a>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-sm-6">
                          <h4>Dates</h4>
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >Begin date</label>
                                <date-picker
                                  v-model="item.begin_date"
                                  format="D MMM Y"
                                  value-type="format"
                                  class="w-100"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >End date</label>
                                <date-picker
                                  v-model="item.end_date"
                                  format="D MMM Y"
                                  value-type="format"
                                  class="w-100"
                                />
                                <small>If this field is not filled out, it is interpreted as present</small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <h4>HOST HISTORY</h4>
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="mb-1 form-group required">
                                <label
                                  for=""
                                  class="form-label"
                                >Do you want to create a related Host History?</label>
                                <select
                                  v-model="relatedHostHistory"
                                  class="form-select"
                                >
                                  <option
                                    value=""
                                    selected
                                  >
                                    Select an option
                                  </option>
                                  <option
                                    value="true"
                                  >
                                    Yes
                                  </option>
                                  <option
                                    value="false"
                                  >
                                    No
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </b-collapse>
                </div>
              </div>

              <IcreaRemarks
                :item="item"
              />

              <AddFile
                :id="6"
                :title="'Support documentation'"
                :type="4"
                :files="item.new_files"
              />

              <InternalNotes
                :notes="item.notes"
                @saveNewNote="saveNewNote"
              />
            </template>
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import IcreaRemarks from '@/views/back/partials/components/IcreaRemarks.vue'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    AddFile,
    SearchResearcher,
    InternalNotes,
    IcreaRemarks,
    vSelect,
    DatePicker,
    BCollapse,
  },
  data() {
    return {
      evaluatorHistoryId: this.$route.params.id,
      dateAlert: 0,
      sending: false,
      errors: '',
      loading: false,
      relatedHostHistory: null,
    }
  },
  computed: {
    ...mapGetters({
      item: 'evaluatorHistories/item',
      statuses: 'status/statuses',
      hosts: 'institutions/institutions',
      evaluatorsTotal: 'users/evaluators',
      institution: 'institutions/institution',
    }),
    userInstitution() {
      return this.item.user
    },
  },
  watch: {
    async userInstitution() {
      if (this.item.user && this.item.user.hosts.length > 0) {
        this.loading = true
        await this.$store.dispatch('institutions/fetchInstitution', this.item.user.hosts[0].id)
        this.item.hostInstitution1 = this.institution
        if (this.item.user.hosts[1]) {
          await this.$store.dispatch('institutions/fetchInstitution', this.item.user.hosts[1].id)
          this.item.hostInstitution2 = this.institution
        }

        this.loading = false
      } else {
        this.item.hostInstitution1 = null
      }
    },
  },
  async mounted() {
    if (this.evaluatorHistoryId) {
      await this.$store.dispatch('evaluatorHistories/fetchId', this.evaluatorHistoryId)
    } else {
      await this.$store.dispatch('evaluatorHistories/cleanType')
    }
  },
  methods: {
    departmentPrioritized() {
      if (!this.item.evaluator && this.item.department1 && this.item.department1.chief && this.item.department1.chief.evaluator) {
        this.item.evaluator = { ...this.item.department1.chief.evaluator, name: this.item.department1.chief.evaluator.full_name }
      } else if (!this.item.evaluator || !this.item.department1) {
        // this.item.evaluator = null
      } else if (this.item.department1 && this.item.department1.chief && this.item.department1.chief.evaluator != this.item.evaluator) {
        this.item.evaluator = { ...this.item.department1.chief.evaluator, name: this.item.department1.chief.evaluator.full_name }
      }
    },
    saveFiles(files) {
      this.item.new_files = files.data
    },
    async save() {
      this.sending = true
      if (this.relatedHostHistory) {
        this.item.relatedHostHistory = this.relatedHostHistory
        try {
          if (this.evaluatorHistoryId) {
            await this.$store.dispatch('evaluatorHistories/update', { id: this.evaluatorHistoryId, data: this.item })
          } else {
            await this.$store.dispatch('evaluatorHistories/create', this.item)
          }
          this.errors = ''
        } catch (e) {
          this.errors = e.response.data.message
        }
        if (this.errors === '' && this.item && this.item.id) {
          if (this.item.last_host_history_id && this.relatedHostHistory === 'true') {
            Vue.swal({
              title: 'The Evaluator history has been saved correctly!',
              html: 'An associated host history has been created',
              icon: 'success',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Check it',
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push({ name: 'researcher.administration.host-history.edit', params: { id: this.item.last_host_history_id } })
              } else {
                this.$router.push({ name: 'researcher.administration.evaluator-history.index' })
              }
            })
          } else {
            Vue.swal('The Evaluator history has been saved correctly!', '', 'success').then(() => {
              this.$router.push({ name: 'researcher.administration.evaluator-history.index' })
            })
          }
        } else {
          // Vue.swal
        }
      } else {
        Vue.swal('Related host history', 'You must select if you want to create the related host history', 'warning')
      }

      this.sending = false
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
